@import 'src/styles/fonts';
@import 'normalize.css';

:root {
  font-size: 16px;
}

html {
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow-y: scroll
}
body {
  min-height: 100%;
  margin: 0;
}

main {
  height: auto;
}

body {
  font-family: 'Manrope', sans-serif;
  background: linear-gradient(90deg, #f8f2ff 0%, #f2f9ff 100%);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}
