@import 'src/styles/mixins';
@import 'src/styles/variables';

.header {
  @include col(12);
  background: $c-white;
  box-shadow: 0px 8px 32px rgba(30, 0, 64, 0.15);
  border-radius: 16px;
  padding: 12px 24px;
  margin-top: 24px;
  box-sizing: border-box;
  position: relative;
}

.header-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
}

.header-logo {
  flex-basis: 146.57px;
  height: 24px;
  background-image: url('../../assets/hi_logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.header-links {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 20px;
}

.header-link {
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;

  svg {
    path {
      stroke: $c-black;
    }
    circle {
      stroke: $c-black;
    }
  }

  &._active {
    svg {
      path {
        stroke: $c-brand;
      }
      circle {
        stroke: $c-brand;
      }
    }
  }

  &._user {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.header-notifications {
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  svg {
    fill: $c-black;
  }

  &._active {
    svg {
      fill: $c-brand;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background-color: $c-brand;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: -4px;
    color: $c-white;
    font-size: 10px;
  }
}

.notifications {
  position: absolute;
  top: 54px;
  right: 0;
  height: auto;
  width: 400px;
  z-index: 999;
  box-shadow: 0 8px 32px rgba(30, 0, 64, 0.15);
  border-radius: 16px;
  background-color: #f3edfa;
}

.user-avatar {
  object-fit: cover;
}

.user-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: $c-black;
}

.user-arrow {
  width: 24px;
  height: 24px;
  background-image: url('../../assets/arrow.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  right: 8px;
}

.menu {
  padding: 16px;
  position: absolute;
  min-width: 113px;
  top: 60px;
  right: 24px;
  background: $c-white;
  box-shadow: 0px 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
  border-radius: 16px;
  z-index: 10;
}

.menu-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: $c-black;
  padding: 8px;
  cursor: pointer;
  display: block;
  &:hover {
    background: #f7f5fa;
    border-radius: 8px;
  }
}

