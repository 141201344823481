@import 'src/styles/mixins';
@import 'src/styles/variables';

.header {
  width: 100%;
}

.header-map {
  position: absolute;
  left: 48px;
  top: 331px;

  path {
    fill: #8a39e6;
    cursor: default;
  }
}

.header-carousel {
  position: relative;
  top: 200px;
  margin-bottom: 100px;
}

.nav {
  @include wrapper;
  padding-top: 57px;
  padding-bottom: 78px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond-to('mobile') {
    display: flex;
    justify-content: space-between;
    padding: 24px 20px;
  }
}

.nav-items {
  &._links {
    display: flex;
    justify-content: space-between;
    flex-basis: 55%;
    align-items: center;
  }
}

.nav-item {
  color: #A04DFF;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  &._logo {
    grid-column: 1 / 3;
    background-image: url('../../../auth/assets/auth_logo.svg');

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 144px;
    min-height: 32px;
    position: relative;
    z-index: 10;
  }

  &._login {
    border: 1.5px solid #A04DFF;
    border-radius: 8px;
    padding: 8px 16px;
  }

  &._burger {
    z-index: 10;
    div {
      width: 22px;
      height: 3px;
      background-color: #A04DFF;
      margin: 4px 0;
    }
  }
}
.mega-wrapper,
.small-wrapper {
  @include wrapper;
}
.mega-title,
.small-title {
  @include col(12);
  color: $c-white;
}
.delimiter {
  display: inline-block;
  padding-left: 32px;
}

.menu {
  width: 100%;
  min-height: 363px;
  position: absolute;
  top: 0;
  background: $c-white;
  box-shadow: 0px 8px 32px rgba(30, 0, 64, 0.15);
  padding: 32px;
}

.menu-item {
  &._visible {
    z-index: 10;
    position: absolute;
    right: -4px;
    top: 0;
    padding-top: 38px;
    box-sizing: border-box;
    height: 68px;
    width: 68px;
    background: #fff;

    div {
      background-color: $c-black;
      position: relative;
      z-index: 10;
      width: 22px;
      height: 2px;

      &:nth-child(1) {
        transform: rotate(45deg);
        background-color: $c-brand;
        top: 1px;
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
        background-color: $c-brand;
        top: -1px;
      }
    }
  }
}

.menu-item {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 24px;
  padding: 0 24px;

  &._services {
    margin-top: 60px;
  }

  &._login {
    border: 1.5px solid $c-black;
    border-radius: 8px;
    padding: 8px;
    margin-left: 24px;
    display: inline-block;
  }
}

