@import 'src/styles/mixins';

.wrapper {
  background: url("../../assets/white-map.svg") no-repeat center 40px;
}

.header {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 0 24px;

  &__logo {
    display: block;
    margin: 0 auto;
  }
}

.offer {
  &__title {
    width: 1024px;
    max-width: calc(100% - 40px);
    letter-spacing: -3.5px;
    margin: 0 auto;
    text-align: center;
  }

  &__description {
    margin: 77px auto 0;
    max-width: 848px;
    width: 100%;
    text-align: center;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    height: 387px;
    box-sizing: border-box;
    padding: 30px 88px;

    @include respond-to('mobile') {
      background: transparent;
      box-shadow: none;
      padding: 0;
      margin-top: 50px;
    }
  }

  &__form {
    background: url('../../assets/offer.png') no-repeat top center;
    background-size: cover;
    margin-top: -270px;
    padding-top: 237px;
    padding-bottom: 18px;

    @include respond-to('mobile') {
      background-position-y: 20px;
      padding-top: 280px;
    }
  }

  &__form-container {
    max-width: 380px;
    width: calc(100% - 40px);
    margin: 0 auto;
  }

  &__form-field {
    background: #fff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
    border: none;
    font-size: 21px;
  }

  &__form-button {
    margin-top: 26px;
    width: 100%;
    color: #fff;
    padding: 38px 0 42px;
    border: none;
    background: linear-gradient(258.57deg, #A04DFF 41.48%, #871EFF 100%);
    box-shadow: 0 12px 40px rgba(160, 77, 255, 0.5), 0 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    cursor: pointer;
    transition: box-shadow 140ms ease-out;

    &:hover {
      box-shadow: 0 6px 20px rgba(160, 77, 255, 0.5), 0 4px 40px rgba(0, 0, 0, 0.2);
    }
  }
}

.teammates {
  &__header {
    display: block;
    position: relative;
    width: 100%;
    max-width: 1024px;
    margin: 75px auto 0;
    text-align: center;

    &::before,
    &::after {
      content: "";
      width: 370px;
      border-top: 1px solid #000;
      position: absolute;
      top: 19px;

      @include respond-to('mobile') {
        width: 30px;
      }
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  &__row {
    display: grid;
    grid-column-gap: 110px;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    max-width: 640px;
    margin: 44px auto 0;

    @include respond-to('mobile') {
      grid-template-columns: 1fr;

      svg {
        margin: 45px auto;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.what-inside {
  margin-top: 120px;

  &__title {
    text-align: center;
    margin: 0 auto;
  }

  &__description {
    width: 100%;
    max-width: 672px;
    margin: 24px auto 0;
    text-align: center;
    font-weight: 400;
  }
}

.press {
  &__header {
    display: block;
    position: relative;
    width: 100%;
    max-width: 1024px;
    margin: 75px auto 0;
    text-align: center;

    &::before,
    &::after {
      content: "";
      width: 420px;
      border-top: 1px solid #000;
      position: absolute;
      top: 19px;

      @include respond-to('mobile') {
        width: 30px;
      }
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  &__row {
    display: grid;
    grid-column-gap: 95px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    max-width: 920px;
    margin: 44px auto 0;

    @include respond-to('mobile') {
      grid-template-columns: 1fr;

      svg {
        margin: 45px auto;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.markets {
  margin-top: 160px;

  &__title {
    text-align: center;
    margin-bottom: 26px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 1024px;
    width: calc(100% - 40px);
    margin: 0 auto;

    @include respond-to('mobile') {
      flex-direction: column;
    }
  }

  &__item {
    width: 33%;
    padding-left: 32px;

    &:last-child {
      padding-right: 0;
    }

    @include respond-to('mobile') {
      width: 100%;
      padding-left: 0;
      margin-bottom: 80px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item-text {
    margin-top: 8px;
  }

  &__item-image {
    height: 192px;
    margin-bottom: 8px;
  }
}

.clients {
  margin-top: 72px;
  margin-bottom: 100px;
}

.buy {
  background: #fff;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: calc(100% - 40px);
  max-width: 1024px;
  margin: -80px auto 0;
  position: relative;
  z-index: 10;
  padding: 40px 55px;
  box-sizing: border-box;

  @include respond-to('mobile') {
    padding: 40px;
    margin-bottom: 20px;
  }

  &__title {
    text-align: center;
    margin-bottom: 40px;
  }

  &__description svg {
    max-width: 100%;

    @include respond-to('mobile') {
      display: none;
    }
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: left;

    @include respond-to('mobile') {
      flex-direction: column;
      align-items: center;
    }
  }

  &__prices {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 32px;

    @include respond-to('mobile') {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 0;
    }
  }

  &__prices-period {
    margin-left: 70px;
    white-space: nowrap;

    @include respond-to('mobile') {
      margin-left: 0;
      margin-bottom: 40px;
    }
  }

  &__form {
    max-width: 380px;
    width: 100%;
    flex-shrink: 0;
    margin-left: 60px;

    @include respond-to('mobile') {
      margin-left: 0;
    }
  }

  &__form-field {
    background: #fff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
    border: none;
    font-size: 21px;
  }

  &__form-button {
    margin-top: 26px;
    width: 100%;
    color: #fff;
    padding: 38px 0 42px;
    border: none;
    background: linear-gradient(258.57deg, #A04DFF 41.48%, #871EFF 100%);
    box-shadow: 0 12px 40px rgba(160, 77, 255, 0.5), 0 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    cursor: pointer;
    transition: box-shadow 140ms ease-out;

    &:hover {
      box-shadow: 0 6px 20px rgba(160, 77, 255, 0.5), 0 4px 40px rgba(0, 0, 0, 0.2);
    }
  }
}

.how {
  margin-top: 120px;
  position: relative;
  background: linear-gradient(90deg, #7925d9 0%, #9d25d9 100%);
  font-size: 0;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-size: contain;
  }

  &__container {
    padding-bottom: 160px;
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    @include respond-to('mobile') {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 40px 40px 60px;
    }
  }

  &__title {
    text-align: center;
    color: #fff;
  }

  &__customize {
    color: #fff;
    max-width: 496px;
    width: calc(100% - 40px);
    margin-top: 76px;
    margin-left: 406px;
    position: relative;
    z-index: 2;

    &::after {
      display: block;
      content: "";
      background: url("../../assets/how-customize-line.svg");
      width: 43px;
      height: 82px;
      position: absolute;
      left: 30px;
      bottom: -90px;
    }

    @include respond-to('mobile') {
      margin-top: 50px;
      margin-left: 0;

      &::after {
        display: none;
      }
    }
  }

  &__customize-image {
    position: absolute;
    top: 67px;
    left: 85px;

    @include respond-to('mobile') {
      position: static;
      margin-top: 50px;
    }
  }

  &__ai {
    color: #fff;
    max-width: 512px;
    width: calc(100% - 40px);
    margin-top: 98px;
    margin-left: 230px;
    position: relative;
    z-index: 2;

    &::after {
      display: block;
      content: "";
      background: url("../../assets/how-ai-line.svg");
      width: 149px;
      height: 95px;
      position: absolute;
      left: 88px;
      bottom: -116px;
    }

    @include respond-to('mobile') {
      width: 100%;
      margin-top: 50px;
      margin-left: 0;

      &::after {
        display: none;
      }
    }
  }

  &__ai-image {
    position: absolute;
    top: 208px;
    right: -170px;

    @include respond-to('mobile') {
      position: static;
      margin-top: 50px;
    }
  }

  &__time {
    color: #fff;
    margin-top: 165px;
    max-width: 546px;
    width: calc(100% - 40px);
    position: relative;
    z-index: 2;
    font-weight: 400;
    margin-left: 500px;

    @include respond-to('mobile') {
      position: static;
      margin-top: -50px;
      margin-left: 0;
    }
  }

  &__time-image {
    position: absolute;
    top: 450px;

    @include respond-to('mobile') {
      position: static;
      margin-top: 0;
    }
  }
}

