.container {
  display: initial;
}

.input {
  box-sizing: border-box;
  min-height: 64px;
  width: 100%;
  padding: 16px;
  box-shadow: 0px 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
  border-radius: 12px;
  border: none;
  &._error {
    border: 1.5px solid #ff3d2b;
  }

  &:disabled {
    color: #cfd7df;
    background-color:transparent;
  }
}

.label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}

.error-text {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ff3d2b;
  margin-bottom: 16px;
}
