@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  @include wrapper;
  grid-gap: 0px 40px;
  margin-bottom: 80px;
}

.form {
  @include col(6);
  background-color: $c-white;
  padding: 40px 35px;
  border-radius: 16px;
}
.step {
  margin-bottom: 10px;
}
.sidebar {
  @include col(6);
}

.auth__logo {
  width: 144px;
  height: 32px;
  background-image: url('../../../auth/assets/auth_logo.svg');
  background-repeat: no-repeat;
  margin: 40px auto;
  cursor: pointer;
}

.sidebar-block {
  padding: 16px;
  background-color: $c-white;
  border-radius: 0px 0px 8px 8px;
}

.sidebar-wrapper {
  max-width: 320px;
  border-radius: 8px 8px 0px 0px;
  border-top: 16px solid rgba(61, 20, 102, 0.7);
  // margin-top: 220px;
}
.sidebar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  &._trial {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.trial-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #918a99;
}

hr.sidebar-border {
  border: none;
  border-top: 0.5px solid #c7c2cc;
}

.sidebar-desc {
  color: #c7c2cc;
}

.sidebar-desc {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #c7c2cc;
  margin: 25px 0;
}
.link {
  color: $c-brand;
  text-align: center;
  margin-top: 24px;
  cursor: pointer;
} 
