.custom-arrow {
    border: 2.8px solid #000000 !important;
    background: white !important;
    color: #000000 !important;
    margin: -50px 0;
    transform: scale(1.3);
    padding: 4px;
  }
  .custom-dot {
    background: #EBE8ED !important;
  }
  .custom-dot.is-active {
    background: #000000 !important;
  }
  .custom-paginate {
     bottom:unset !important;
  }