@import 'src/styles/mixins';
@import 'src/styles/variables';

.zoom, .container, .task {
  background-color: $c-white;
  border-radius: 8px;
  padding: 16px;

  &:not(:first-child){
    margin-top: 16px;
  }
}

.zoom {
  text-align: center;
}

.zoom__actions {
  margin-top: 16px;
}

.zoom__decline,
.zoom__accept {
  display: block;
  width: 100%;
  border: none;
  text-align: center;
  text-decoration: none;
  color: $c-white;
  font-weight: 800;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;

  &:hover, &:focus {
    text-decoration: none;
  }
}
.zoom__accept {
  background-color: #00C236;
  margin-bottom: 8px;
  margin-top: 36px;
}

.zoom__decline {
  background-color: #FF4433;
}

.message {
  background-color: #F3EDFA;
  box-shadow: 0 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
  border-radius: 8px;
  padding: 16px;

  &._clickable {
    cursor: pointer;
  }
}

.task__header, .sender  {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.task__header {
  margin-bottom: 11px;
}

.sender {
  margin-left: 0.2em;

  &__avatar {
    margin-right: 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    flex-shrink: 0;
  }
}
