@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: initial;
}

.splide__slide__container {
  background-image: url('../../assets/report_blank.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 264px;
  width: 193px;
}

.report-title {
  padding: 40px 100px;
  background-image: url('../../assets/report_title.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 6px;
  width: 50px;
  margin-bottom: -25px;
}
.report-body {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  padding-left: 30px;
  padding-right: 30px;
  /* or 133% */

  text-align: center;
  letter-spacing: -0.5px;

  color: #ffffff;
}
.report-year {
  position: absolute;
  bottom: 40px;
  left: 55px;
  right: 35px;
  font-style: normal;
  font-weight: 800;
  font-size: 3.2px;
  line-height: 4px;
  text-align: center;
  letter-spacing: -0.05em;

  color: #ffffff;
}
