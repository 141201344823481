@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: initial;
}
.indicators-blocks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px 8px;
  margin: 50px 16px 50px 16px;
}
.indicators-label {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  color: #000000;
  grid-column: span 5;
}
.indicators-values {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  color: #000000;
  grid-column: span 7;
}
.indicators-group-value {
  display: flex;
  margin-bottom: 5px;
}
.indicators-block {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.indicators-value {
  margin: 0 5px;
}
.indicators-img {
  margin: 2px 0;
}
