@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  @include wrapper;

  &._reviews {
    display: flex;
    gap: 32px;
    @include respond-to('mobile') {
      overflow-x: scroll;
      gap: 8px;
      padding-left: 20px;
    }
  }
}

.review-title {
  @include col(12);
  text-align: center;
  margin-bottom: 40px;
}

.review {
  @include col(4);
  min-height: 266px;
  background: $c-white;
  border-radius: 24px;
  padding: 24px;
  max-width: 320px;

  @include respond-to('mobile') {
    margin-top: 0;
    min-width: 296px;
  }

  // &:nth-child(1) {
  //   grid-column: 1 / 5;
  // }
  // &:nth-child(2) {
  //   grid-column: 5 / 9;
  // }
  // &:nth-child(3) {
  //   grid-column: 9 / -1;
  // }
}
.review-block {
  display: flex;
  gap: 8px;
}
.review-avatar {
  width: 56px;
  height: 56px;
  background-image: url('../../assets/userpic_arlene.jpg');
  background-size: contain;
  border-radius: 50%;
  background-repeat: no-repeat;

  &._russel {
    background-image: url('../../assets/userpic_russel.jpg');
  }

  &._darrel {
    background-image: url('../../assets/userpic_darrel.jpg');
  }
}
.review-comment {
  margin-top: 8px;
}
