@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  max-width: 498px;
  margin: 40px auto 0;
  background: $c-white;
  border-radius: 16px;
  padding: 32px 40px;
}

.auth__logo {
  width: 144px;
  height: 32px;
  background-image: url('../../assets/auth_logo.svg');
  background-repeat: no-repeat;
  margin: 40px auto;
  cursor: pointer;
}