@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  @include wrapper;

  @include respond-to('mobile') {
    margin-top: 80px;
  }
}

.job-checkboxes {
  @include col(12);
  margin: 80px 0;
  background: $c-white;
  border-radius: 32px;

  gap: 32px;
  padding: 64px 64px 40px;

  @include respond-to('mobile') {
    padding: 24px;
    margin-top: 80px;
    margin: 0 20px;
  }

  &__header {
    width: 100%;
  }

  &__header-default {
    display: block;
    margin: 0 auto 20px;

    @include respond-to('mobile') {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @include respond-to('mobile') {
      flex-wrap: wrap;

      & * {
        width: 100%;
      }
    }
  }
}

.job-checkmark {
  width: 24px;
  height: 24px;
  background-image: url('../../assets/checkmark_green.svg');
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
}

.job-text {
  min-height: 28px;
}

.job-description {
  margin-top: 4px;
}

.job-checkbox {
  margin: 32px 0;
  display: flex;
  gap: 8px;
  align-items: flex-start;

  @include respond-to('mobile') {
    &:first-child {
      margin-top: 0;
    }
  }
}
