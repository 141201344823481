.container {
  display: initial;
}
.modal {
  width: 438px;
  padding: 40px;
  border-radius: 16px;
}
.form-description {
  margin: 24px 0;
}
