.button {
  margin-top: 20px;
  margin-bottom: -20px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #4A8CFF;
  width: 100%;
  border: none;
  font-size: 16px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  transition: background-color 140ms ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;

  &:hover {
    background-color: darken(#4A8CFF, 10);
  }

  &._success {
    background-color: #00C236;
  }

  &[disabled] {
    background-color: #d1d1d1;
    padding: 0;
    cursor: default;
  }
}

.spinner circle {
  stroke: #fff !important;
}
