@import 'src/styles/variables';
@import 'src/styles/mixins';

.text {
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.header-xx-large {
  font-size: 4.5rem;
  line-height: 4.5rem;
  font-weight: 800;
  letter-spacing: -0.0219em;
  @include respond-to('mobile') {
    font-size: 3.375rem;
    line-height: 3.5rem;
    font-weight: 800;
    letter-spacing: -0.0141em;
  }
}

.header-x-large {
  font-size: 3.375rem;
  line-height: 3.5rem;
  font-weight: 800;
  letter-spacing: -2.9px;

  @include respond-to('mobile') {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 800;
    letter-spacing: -0.0109em;
  }
}

.header-large {
  font-size: 2.75rem;
  line-height: 3rem;
  font-weight: 800;
  letter-spacing: -0.0109em;

  @include respond-to('mobile') {
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 800;
    letter-spacing: -0.0109em;
  }
}

.header-medium {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 800;
  letter-spacing: -0.0109em;
  @include respond-to('mobile') {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
    letter-spacing: -0.0078em;
  }
}

.header-small {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 800;
  letter-spacing: -0.0078em;
}

.header-x-small {
  font-size: 1.313rem;
  line-height: 1.75rem;
  font-weight: 800;
  letter-spacing: -0.0063em;
}

.text-x-large-bold,
.text-large-bold,
.text-large b,
.text-large strong,
.text-medium-bold,
.text-medium b,
.text-medium strong,
.text-small b,
.text-small strong {
  font-weight: 800;
}

.text-xx-large {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
}
.text-x-large-bold,
.text-x-large-medium,
.text-x-large {
  font-size: 1.313rem;
  line-height: 1.75rem;
}

.text-x-large-medium {
  font-weight: 600;
}

.text-x-large {
  font-weight: 400;
}

.text-large {
  font-size: 1.094rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.text-medium {
  font-size: 0.938rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.text-small {
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 400;
}

.text-icon {
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 800;
}
