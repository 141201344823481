@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: initial;
}

.digital-companies-blocks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px 32px;
}
.digital-companies-block {
  display: grid;
  grid-template-columns: 12fr;
  grid-gap: 8px 8px;
  background: #ffffff;
  /* Input Shadow */

  box-shadow: 0px 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
  border-radius: 24px;
  width: 496px;
  height: 148px;
  padding: 32px 24px;
}
.digital-companies-logo {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #ffffff;
  /* Gray 2 */

  border: 1px solid #ebe8ed;
  margin-top: -43px;
}
.digital-companies-name {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: -0.5px;

  color: #000000;
}
.digital-companies-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Gray 5 */

  color: #9a989c;
}
.digital-companies-info {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  color: #000000;
}
.digital-companies-price-img {
  background-image: url('../../assets/price.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 9px;
  width: 15px;
  margin-right: 4px;
  margin-top: 4px;
}
.digital-companies-size-img {
  background-image: url('../../assets/size.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 13px;
  width: 13px;
  margin-right: 4px;
}
.digital-companies-cost-img {
  background-image: url('../../assets/cost.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 15px;
  width: 15px;
  margin-right: 4px;
}
.digital-companies-location-img {
  background-image: url('../../assets/location.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 15px;
  width: 13px;
  margin-right: 4px;
}
.digital-companies-price, .digital-companies-size, .digital-companies-cost, .digital-companies-location {
  margin-right:16px;
}
