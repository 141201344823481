@import 'src/styles/mixins';
@import 'src/styles/variables';

.content {
  @include col(8);
  margin-top: 40px;
  min-height: calc(100vh - 160px);
  padding-bottom: 20px;
}


.user-avatar {
  object-fit: cover;
  margin-bottom: -2px;
}

.manager-avatar {
  object-fit: cover;
  margin-bottom: -5px;
}
.content-welcome {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.0375em;
  color: #c7c2cc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-select {
  flex-basis: 160px;
}
.chat {
  @include col(4);
  margin-top: 40px;
  align-self: auto;
}

.chat-container {
  top: 140px;
  position: sticky;
  background: $c-white;
  box-shadow: 0 4px 16px rgba(56, 38, 77, 0.04);
  border-radius: 16px;
  height: 957px;
  max-height: calc(100vh - 160px);
  overflow: hidden;
}

