.container {
  display: initial;
}

.card-label {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  margin-top: 16px;
}

.card-input {
  padding: 24px 16px;
  width: 100%;
  box-shadow: 0px 1px 8px rgb(60 0 128 / 5%), 0px 2px 16px rgb(60 0 128 / 5%);
  border-radius: 12px;
  border: none;
}

.card-items {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}
.card-item {
  flex-basis: 50%;
}
.button {
  margin-top: 32px;
}
