@import 'src/styles/mixins';
@import 'src/styles/variables';

.mobile-error {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.logo {
  grid-column: span 12;
  text-align: center;
  width: 144px;
  height: 32px;
  background-image: url('../../../auth/assets/auth_logo.svg');
  background-repeat: no-repeat;
  margin: 40px auto;
  cursor: pointer;
}

.header {
  grid-column: span 12;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* or 117% */

  text-align: center;
  letter-spacing: -0.01em;

  color: #000000;
  padding: 0 40px;
}

.body {
  grid-column: span 12;
  text-align: center;
  width: 320px;
  height: 220px;
  background-image: url('../../assets/mobile-error.svg');
  background-repeat: no-repeat;
  margin-top: 40px;
  place-self: center;
  cursor: pointer;
}

.footer {
  grid-column: span 12;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* or 117% */

  text-align: center;
  letter-spacing: -0.01em;

  color: #000000;
  padding: 0 40px 40px 40px;
}
