@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  max-width: 1024px;
  margin: -30px auto 100px;
}

.text-page {
  @include static-page();
}
