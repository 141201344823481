.data-labels > text {
  font-family: 'Manrope';
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: -0.04em;

  color: #000000 !important;
}
.menu-active {
  background: #000000;
  box-shadow: 0px 2px 16px rgba(30, 0, 64, 0.25);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: -0.01em;
  width: 100%;

  /* White */

  color: #ffffff !important;
}
.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #cccccc;
  stroke-dasharray: 2.5;
}
.highcharts-axis-labels {
  font-family: 'Manrope';
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: -0.02em;

  /* Gray 5 */

  color: #9a989c !important;
}
.highcharts-credits {
  display: none;
}
.menu-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: -0.01em;

  /* Black */

  color: #000000;
  padding: 8px;
}

.menu-title:hover {
  background: #000000;
  box-shadow: 0px 2px 16px rgba(30, 0, 64, 0.25);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: -0.01em;
  width: 100%;

  /* White */

  color: #ffffff;
}
