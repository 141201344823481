@import 'src/styles/mixins';

.what-inside {
  &__grid-container {
    width: 960px;
    max-width: calc(100% - 40px);
    margin: 64px auto 0;
    position: relative;
    padding-bottom: 30px;
  }

  &__grid {
    width: 100%;
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 0.9fr 1fr 0.9fr;

    @include respond-to('mobile') {
      display: block;
    }
  }

  &__grid-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    & svg {
      flex-shrink: 0;
      margin-right: 8px;
    }

    @include respond-to('mobile') {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  &__more {
    position: absolute;
    bottom: 0;
    right: -25px;
  }
}
