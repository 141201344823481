@mixin wrapper {
  display: grid;
  max-width: 1024px;
  margin: 0 auto;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0px 32px;

  @include respond-to('mobile') {
    width: 100%;
    max-width: 100%;
    display: block;
  }
}

@mixin profile-wrapper {
  display: grid;
  max-width: 1040px;
  margin: 0 auto;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0px 40px;
}

@mixin col($count) {
  grid-column: span #{$count};
}

$breakpoints: (
  'landscapeTablet': (
    max-width: 1280px,
  ),
  'tablet': (
    max-width: 1024px,
  ),
  'mobileLandscape': (
    max-width: 768px,
  ),
  'mobile': (
    max-width: 568px,
  ),
) !default;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

.test-class-name {
  @include respond-to('mobile') {
    // стили для мобилок
  }
}


@mixin static-page() {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
    margin-top: 40px;
  }

  p {
    margin: 20px 0;
    line-height: 1.5;
  }

  ul {
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 6px;
  }
}
