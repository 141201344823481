@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: initial;
}
.countries {
  padding: 16px 0;
  border-bottom: 1px solid #c7c2cc;
  margin-bottom: 16px;
}
.payment-method {
  margin-bottom: 8px;
}
.plans {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 2px solid;
  border-radius: 4px;
  margin-bottom: 8px;

  &._purple {
    border-color: $c-brand;
  }
  &._gray {
    border-color: #c7c2cc;
  }
}

.plan {
  text-transform: capitalize;
  &._purple {
    color: $c-brand;
    cursor: pointer;
  }
  &._gray {
    color: #cdcccf;
  }
}
.prolong-title {
  color: $c-green;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin-top: 24px;
  margin-bottom: 16px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.button-wrapper {
  width: 218px;

  &._link {
    width: 153px;
  }
}
.button-link {
  background: none;
  color: #c7c2cc;
  box-shadow: none;
  text-align: right;
  font-weight: normal;
  font-size: 16.5px;
  line-height: 24px;
  letter-spacing: 0.01em;

  &:hover {
    background: transparent;
  }
}
.products {
  margin-top: 40px;
}
.product {
  box-shadow: 0px 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
  border-radius: 16px;
  padding: 40px;
  margin-bottom: 32px;
}
.product-descriptions {
  padding-top: 40px;
  padding-bottom: 16px;
  border-bottom: 1px solid #c7c2cc;
}
.product-languages {
  color: #918a99;
  margin-top: 4px;
}

.product-manager {
  display: flex;
  align-items: center;
}

.product-avatar {
  border-radius: 50%;
  object-fit: cover;
  margin: 0 8px;
}

.product-services {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  margin-bottom: 28px;
}

.product-service {
  &._purple {
    color: $c-brand;
    cursor: pointer;
  }
}

.product-checkbox {
  padding-left: 32px;
  background-image: url('../../../../assets/icons/green_checkmark.svg');
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: left center;
  margin-bottom: 8px;
}
.product-name {
  display: flex;
  gap: 8px;
  align-items: center;
}
.product-flag {
  object-fit: fill;
}

.actions {
  display: flex;
  gap: 16px;
}
.delete {
  cursor: pointer;
  color: #ff4433;
}
.payment-title {
  text-align: right;
  color: $c-brand;
  cursor: pointer;
  margin-top: 8px;
}
