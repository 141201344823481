@import 'src/styles/mixins';
@import 'src/styles/variables';

.auth__logo {
  width: 139px;
  height: 25px;
  background-image: url('../../assets/auth_logo.svg');
  background-repeat: no-repeat;
  margin: 40px auto;
  cursor: pointer;
}

.button {
  min-height: 64px;
}

.auth__block {
  max-width: 760px;
  margin: 0 auto;
  background: $c-white;
  border-radius: 16px;
  padding: 32px 40px;

  &._countries {
    max-width: 1040px;
    padding: 32px 64px;
    position: relative;
    min-height: 800px;
    margin-bottom: 40px;
  }

  &._auth {
    max-width: 498px;
  }
  &._company {
    max-width: 498px;
  }
}

.auth__step {
  color: #918a99;
}

.auth__title {
  margin-top: 8px;
  color: $c-black;
}

.auth__description {
  color: $c-black;
  margin-bottom: 16px;
  margin-top: 16px;
}

.auth__country-title {
  margin-top: 20px;
  margin-bottom: 8px;
}

.input {
  margin-bottom: 8px;
}

.auth__checkboxes {
  display: grid;
  grid-template-columns: 282px 282px;
}
.button__wrapper {
  width: 100%;
  margin-top: 16px;

  &._checkout {
    width: 100%;
    margin: 0;
    margin-top: 24px;
  }
}

.button {
  background: #842ee6;
  border-radius: 12px;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0375em;
  color: $c-white;
}

.auth__checkout {
  padding: 32px;
  position: absolute;
  width: 283px;
  min-height: 372px;
  top: 184px;
  right: 64px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(60, 0, 128, 0.1);
  border-radius: 24px;
}

.prices {
  margin-top: 24px;
  padding-bottom: 8px;
  border-bottom: 0.5px solid #c7c2cc;
}
.price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  &._total {
    padding-top: 24px;
    margin: 0;
  }
}

.auth__flag {
  object-fit: cover;
  margin-right: 8px;
}

.checkbox__label {
  display: flex;
  align-items: center;
}


.link {
  color: $c-brand;
  text-align: center;
  margin-top: 24px;
  cursor: pointer;
} 
