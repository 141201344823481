//colors

$c-brand: #a04dff;
$c-black: #000;
$c-white: #fff;
$c-violet-secondary: #C999FF;
$c-light-orange: rgba(246, 146, 30, 0.3);
$c-cyan: #00e5ac;
$c-gray-level-four: #636166;
$c-green: #00c437;
