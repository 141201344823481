@import 'src/styles/mixins';
@import 'src/styles/variables';

.header {
  background-color: $c-brand;
  color: $c-white;
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 17px;
}

.container {
  padding-bottom: 20px;
  max-height: calc(100vh - 100px);
  overflow: hidden;
}

.close {
  margin: 0 0 0 auto;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 0;
}

.body {
  max-height: calc(100vh - 20px);
  overflow: scroll;
}

.body-wrapper {
  padding: 16px;
  max-width: 100%;
}

.kostyl {
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -33px;
  left: 122px;
}
