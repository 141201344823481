@import 'src/styles/mixins';
@import 'src/styles/variables';

.content {
  @include col(9);
  min-height: 80vh;
  background-color: $c-white;
  box-shadow: 0px 8px 32px rgba(30, 0, 64, 0.15);
  border-radius: 16px;
  margin-top: 64px;
  margin-bottom: 64px;
  padding: 48px 40px;
}

.sidebar {
  @include col(3);
  margin-top: 64px;
}
