@import 'src/styles/mixins';
@import 'src/styles/variables';

.country-manager {
  background: linear-gradient(90deg, #f8f2ff 0%, #f2f9ff 100%);

  @include respond-to('mobile') {
    margin: 0 20px;
  }
}
.wrapper {
  @include wrapper;
}

.who {
  background: url('../../assets/manager.png') no-repeat 620px top;
  background-size: 347px 320px;
  padding-top: 33px;
  @include col(12);

  &__description {
    max-width: 584px;
  }

  &__description-title {
    margin-bottom: 40px;
  }

  &__description-sub-title {
    margin-bottom: 24px;
  }
}

.how {
  @include col(12);
  margin: 72px 0;

  @include respond-to('mobile') {
    text-align: center;
  }


  &__examples {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 33px;
    margin-top: 16px;

    @include respond-to('mobile') {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      row-gap: 33px;
    }
  }

  &__example {
    max-width: 320px;

    @include respond-to('mobile') {
      margin: 0 auto;
    }
  }

  &__example-image {
    width: 320px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EEE9F5;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 33px;
    margin-bottom: 8px;
  }

  &__example-image img {
    max-width: 75%;
  }

  &__example-description *:last-child {
    margin-top: 8px;
  }
}

.responsibilities-title {
  margin-bottom: 33px;
}

.reviews {
  @include col(12);
  margin-bottom: 20px;

  @include respond-to('mobile') {
    margin: 0 20px 20px;
  }

  &__title {
    font-size: 44px;
    line-height: 48px;
    text-align: left !important;
    margin-bottom: 33px;
  }
}

.offer {
  width: 100%;
  max-width: 516px;
  margin: 80px auto 135px;
  box-sizing: border-box;

  @include respond-to('mobile') {
    padding: 0 20px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__description {
    margin-bottom: 34px;
  }

  &__button {
    background: #FFFFFF;
    border-radius: 16px;
    width: 100%;
    color: #A04DFF;
    padding: 24px 24px 28px;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 16px rgba(30, 0, 64, 0.1);
    transition: box-shadow 140ms ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
      box-shadow: 0 2px 8px rgba(30, 0, 64, 0.15);

    }
  }

  &__button-text {
    margin: 0 auto;
  }

  &__button-icon {
    position: absolute;
    top: 24px;
    right: 24px;
    flex-shrink: 0;
    margin-left: auto;
    transform: rotate(180deg);

    path {
      stroke: #A04DFF !important;
    }
  }


}
