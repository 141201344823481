@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: initial;
}

.charts-body {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.charts-menu {
  grid-column: span 2;
  margin-right: 34px;
}
.charts-graphs {
  display: grid;
  grid-column: span 10;
}

.graphs-country {
  margin-bottom: 10px;
  display: flex;
}
.graphs-flag {
  width: 12px;
  height: 12px;
  padding: 2px;
  margin-top: -1px;
  margin-right: 2px;
  margin-left: 10px;
}

.graphs-country-name {
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 8px;
  /* identical to box height, or 100% */

  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: #000000;
}

.graphs-title {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  letter-spacing: -0.048em;

  /* Black */

  color: #000000;
  margin-bottom: 24px;
  margin-left: 10px;
}
.graphs-subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  color: #000000;
  margin-left: 10px;
  margin-bottom: 5px;
}
