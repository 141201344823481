.container {
  background: #ffdcd9;
  border: 1px solid #ff4433;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px 40px 16px 16px;
  position: relative;
  margin-bottom: 16px;
}

.icon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  &:hover path {
    fill: #000;
  }
}
