@import 'src/styles/mixins';
@import 'src/styles/variables';

.reports,
.indicators {
  @include col(12);
  background-color: $c-white;
  box-shadow: 0px 8px 32px rgba(30, 0, 64, 0.15);
  border-radius: 24px;
  margin-top: 40px;
  padding: 24px 40px;
}
.closure-reports {
  background-image: url('../../assets/closure-reports.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 352px;
  width: 1024px;
  margin-top: 40px;
  position: relative; 

}
.reports-empty {
  @include col(12);
  background-color: $c-white;
  box-shadow: 0px 8px 32px rgba(30, 0, 64, 0.15);
  border-radius: 24px;
  margin-top: 40px;
}
.closure-reports:after {
  font-size:14px;
  line-height: 450px;
  color: #000000; 
  text-align: center;
  content: url("../../assets/closure-reports-soon.svg");
	display: block;    
	width: 100%;
	height: 95%;    
	position: absolute;
	top: 0;      
	left: 0;   
	background: transparent;
	background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.7) 0%, #FFFFFF 100%);
}

.indicators {
  margin-bottom: 40px;
}
.experts {
  @include col(12);
  border-radius: 24px;
  margin-top: 40px;
  margin-bottom: 76px;
}
.digital-companies {
  @include col(12);
  border-radius: 24px;
  margin-bottom: 40px;
}

.custom-report-info {
  @include col(12);
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 48px 40px;
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  height: 324px;
  background: #a04dff;
  box-shadow: 0px 16px 64px rgba(160, 77, 255, 0.8);
  border-radius: 24px;
}
.custom-report-left {
  display: grid;
  grid-row-gap: 2rem; /* Will be used instead by browsers that do not support `row-gap` */
  row-gap: 1rem;
  grid-column: span 6;
}
.custom-report-right {
  background: url('../../assets/custom-report.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 244px;
  width: 454px;
  grid-column: span 6;
}

.custom-report-title {
  line-height: 2rem;
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 48px;
  /* identical to box height, or 109% */

  letter-spacing: -1.75px;

  color: #ffffff;
}
.custom-report-description {
  line-height: 2rem;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  letter-spacing: 0.0025em;

  color: #ffffff;
}

.custom-report-price {
  line-height: 2rem;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  /* Violet Secondary 4 */

  color: #c999ff;
  margin-right: 5rem;
}
.custom-report-button {
  display: flex;
  line-height: 2rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 16px 24px 24px;
  background: #ffffff;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  line-height: 28px;
  /* identical to box height, or 133% */

  letter-spacing: -1px;

  color: #000000;

  /* Big Shadow */

  box-shadow: 0px 8px 32px rgba(30, 0, 64, 0.15);
  border-radius: 16px;

  cursor: pointer;
  width: 276px;
  height: auto;
  justify-self: center;
  margin-right: 5rem;
}
.report-block-title,
.indicator-block-title {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  /* identical to box height, or 111% */

  letter-spacing: -1.75px;

  color: #000000;
  text-align: center;
}
.experts-block-title-group {
  border: 3.25px solid #000000;
  box-sizing: border-box;
  border-radius: 9px;
  margin-left: 5px;
  padding: 4px 8px;
}
.experts-block-title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: -1.25px;

  color: #000000;
  display: flex;
  justify-content: center;
  place-items: center;
  margin-bottom: 16px;
}
.experts-block-title-img {
  width: 24px;
  height: 24px;
  padding-top: 8px;
  padding-right: 2px;
}
.country-manager-info {
  @include col(12);
  margin-bottom: 66px;
  padding: 24px 78px;
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  height: 216px;
  background: #a04dff;
  box-shadow: 0px 16px 64px rgba(160, 77, 255, 0.8);
  border-radius: 24px;
}
.country-manager-left {
  display: grid;
  grid-row-gap: 2rem; /* Will be used instead by browsers that do not support `row-gap` */
  row-gap: 1rem;
  grid-column: span 6;
}
.country-manager-right {
  background: url('../../assets/country-manager.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 237px;
  width: 250px;
  grid-column: span 6;
  margin-top: -70px;
  justify-self: center;
}
.country-manager-title {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  /* or 111% */

  letter-spacing: -1.75px;

  color: #ffffff;
}
.country-manager-button-block {
  display: flex;
  justify-content: center;
}
.country-manager-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 16px 93px;

  /* White */

  background: #ffffff;
  /* Big Shadow */

  box-shadow: 0px 8px 32px rgba(30, 0, 64, 0.15);
  border-radius: 8px;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: -0.75px;

  color: #000000;
  cursor: pointer;
}
.country-manager-price {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Violet Secondary 4 */

  color: #c999ff;
  margin-left: 20px;
  place-self:center;
}

.digital-companies-block-title-group {
  border: 3.25px solid #000000;
  box-sizing: border-box;
  border-radius: 9px;
  margin-left: 5px;
  padding: 4px 8px;
}
.digital-companies-block-title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: -1.25px;

  color: #000000;
  display: flex;
  justify-content: center;
  place-items: center;
  margin-bottom: 32px;
}
.digital-companies-block-title-img {
  width: 24px;
  height: 24px;
  padding-top: 8px;
  padding-right: 2px;
}
