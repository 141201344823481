.attachment {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
}

.attachment-image {
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  margin-right: 16px;
}

.attachment-name {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 4px;
}

.attachment-date {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0375em;
  color: #918a99;
}

.attachment-delete {
  font-family: Manrope, sans-serif;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #918a99;
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
  border-radius: 0;
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.attachment-loading {
  width: 120px;
  height: 80px;
  border-radius: 8px;
  background-color: #F7F5FA;
  margin-right: 16px;
}
