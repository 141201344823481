.container {
  display: initial;
}

.modal {
  padding: 56px;
  width: 440px;
  border-radius: 24px;
}

.modal-title {
  margin-bottom: 32px;
}

.modal-field {
  margin-bottom: 16px;

  &._current {
    margin-bottom: 40px;
  }
}

.modal-buttons {
  display: flex;
  gap: 8px;
}

.form__button {
  margin-top: 40px;
  &._gray {
    background: #cdcccf;
  }
}
