@import 'src/styles/mixins';
@import 'src/styles/variables';

.content {
  @include col(12);
  text-align: center;
}
.block {
  padding: 56px 64px;
  background-color: $c-white;
  border-radius: 16px;
  max-width:496px;
  margin: 0 auto;
  text-align: left;
  margin-top: 64px;
}

.description {
    margin-top: 16px;
    margin-bottom: 32px;
}