@import 'src/styles/mixins';
@import 'src/styles/variables';

.button__wrapper {
  width: 100%;
  margin-top: -50px;
}

.button {
  min-height: 64px;
  margin-top: 32px;
}

.input {
  background-repeat: no-repeat;
  background-size: 24px 24px;
  padding-left: 48px;
  background-position: 16px center;

  &._email {
    background-image: url('../../assets/icon_email.svg');
    background-repeat: no-repeat;
  }
  &._password {
    background-image: url('../../assets/icon_lock.svg');
    background-repeat: no-repeat;
    background-position: 16px center;
  }
}

.input:not(:last-of-type) {
  margin-bottom: 16px;
}

.password-wrapper {
  margin-top: 16px;
  position: relative;
  cursor: pointer;
}

.password-eyes {
  width: 24px;
  height: 24px;
  background-image: url('../../assets/icon_eye_open.svg');
  background-repeat: no-repeat;
  position: absolute;
  right: 16px;
  top: 50px;

  &._visible {
    background-image: url('../../assets/icon_eye_close.svg');
  }
}

.login-forgot {
  color: $c-brand;
  text-align: center;
  margin-top: 24px;
  cursor: pointer;
}

.login-title {
  margin-bottom: 32px;
}
.recaptcha {
  visibility: collapse;
}

.link {
  color: $c-brand;
  text-align: center;
  margin-top: 24px;
  cursor: pointer;
} 