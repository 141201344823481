@import 'src/styles/mixins';
@import 'src/styles/variables';

.modal {
  padding: 24px 24px 64px;
  width: 800px;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(30, 0, 64, 0.15);
  border-radius: 16px;
}
.modal-title {
  font-weight: 800;
  font-size: 44px;
  line-height: 48px;
  letter-spacing: -0.0375em;
  color: $c-black;
  margin-top: 48px;
  padding-right: 40px;
  padding-left: 40px;
}

.modal-description {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $c-black;
  letter-spacing: 0.01em;
  margin-top: 16px;
  padding-right: 40px;
  padding-left: 40px;
}

.modal-box {
  box-shadow: 0px 8px 32px rgba(30, 0, 64, 0.15);
  border-radius: 24px;
  padding: 32px 40px 40px;
  margin-top: 32px;
}

.box-title {
  font-weight: 800;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.0375em;
  color: $c-black;
}

.box-statuses {
  background: #f7f5fa;
  border-radius: 16px;
  margin-top: 16px;
  padding: 24px;
}
.status-title {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
  color: $c-black;
}

.status {
  display: inline-block;
}

.status {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: $c-white;
  padding: 16px;
  border-radius: 12px;
  margin-right: 8px;
  cursor: pointer;
  background: #c7c2cc;

  &._active {
    background: #a04dff;
  }
  &._disabled {
    background: #c7c2cc;
    opacity: 0.2;
    cursor: not-allowed;
  }
}

.modal-attachments {
  padding-right: 40px;
  padding-left: 40px;
}

.attachments-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.025em;
  color: $c-black;
  margin-top: 32px;
  margin-bottom: 24px;
}

.attachment-input {
  color: transparent;
  margin-top: 24px;
}
.attachment-input::-webkit-file-upload-button {
  visibility: hidden;
}
.attachment-input::before {
  content: 'Add an attachment';
  display: inline-block;
  outline: none;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  background: #c7c2cc;
  border-radius: 8px;
  padding: 8px 12px;
  color: $c-white;
}
.attachment-input:hover::before {
  border-color: black;
}
.attachment-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.modal-comments {
  margin-top: 32px;
  padding-right: 40px;
  padding-left: 40px;
}
.comments-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.025em;
  color: $c-black;
}

.comments-form {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 24px;
}

.comments-avatar {
  object-fit: cover;
}

.comments-textarea {
  width: 100%;
  resize: none;
  border: none;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
  border-radius: 12px;
  padding: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-family: Manrope;
  &:placeholder {
    color: #c7c2cc;
  }
}

.comments-button {
  height: 36px;
  width: 58px;
  background: #c7c2cc;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: $c-white;
  padding: 8px 12px;
}

.comments-button-wrapper {
  margin-top: 8px;
  text-align: right;
}

.comments-blocks {
  margin-top: 24px;
}
.comments-avatar {
  min-width: 32px;
  min-height: 32px;
  background-image: url('../../assets/userpic.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.comments-block {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 24px;
}

.comments-message {
  width: 100%;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: $c-black;
  margin-bottom: 4px;
}

.comments-time {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0375em;
  color: #918a99;
}
