@import 'src/styles/mixins';
@import 'src/styles/variables';

.main {
  background: linear-gradient(90deg, #f8f2ff 0%, #f2f9ff 100%);
}
.wrapper {
  @include wrapper;
}

.manager-blocks {
  @include col(10);
  grid-column: 2 / 12;

  @include respond-to('mobile') {
    margin: 0 20px;
  }
}

.manager-block {
  padding: 80px 0;
  background-image: url('../../assets/manager.png');
  background-repeat: no-repeat;
  background-size: 320px 306px;
  background-position: center right;

  @include respond-to('mobile') {
    text-align: center;
    padding: 0;
    padding-top: 324px;
    background-position: top;
    margin-top: 80px;

    &:nth-child(1) {
      margin-top: 240px;
    }
  }

  &._personalized {
    background-image: url('../../assets/personalized.png');
    background-size: 340px 219px;

    @include respond-to('mobile') {
      padding-top: 240px;
    }
  }
  &._directed {
    background-image: url('../../assets/directed.png');
    background-size: 340px 232px;
    background-position: center left;
    display: flex;
    justify-content: flex-end;

    @include respond-to('mobile') {
      background-position: top;
      padding-top: 240px;
    }
  }

  &._tasked {
    background-image: url('../../assets/tasked.png');
    background-size: 340px 219px;
    @include respond-to('mobile') {
      padding-top: 240px;
    }
  }
}

.manager-description {
  margin: 24px 0;
}
.manager-caption {
  margin-top: 8px;
}

.manager-link {
  color: $c-brand;
  cursor: pointer;
}

.benefit-megatitle {
  @include col(12);
  margin-bottom: 40px;
  margin-top: 64px;
}
.benefits-content {
  @include col(12);
  background-image: url('../../assets/benefits_background.svg');
  background-size: 531px 488px;
  background-repeat: no-repeat;
  padding-left: 273px;
  @include respond-to('mobile') {
    padding-left: 0;
    padding: 0 20px;
    background-size: 332px 331px;
  }
}
.benefits {
  margin-bottom: 32px;
}

.benefit-checkbox {
  display: flex;
  gap: 16px;
}

.benefit-checkmark {
  width: 40px;
  height: 40px;
  background-image: url('../../assets/checkmark_purple.svg');
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
}

.benefit-description {
  margin-top: 8px;
}

.stats {
  @include col(12);
  min-height: 672px;
  background-image: url('../../assets/earth.png'), linear-gradient(90deg, #7621d9 0%, #8c21d9 100%);
  background-size: 682px 565px, cover;
  background-repeat: no-repeat, no-repeat;
  background-position: 0px 80px, center center;
  border-radius: 32px;
  margin: 100px 0;
  padding: 64px;
  display: flex;
  justify-content: flex-end;

  @include respond-to('mobile') {
    margin: 0 20px;
    background-size: 311px 255px, cover;
    background-position: center 40px, center center;
    padding: 0;
    justify-content: center;
    padding-bottom: 20px;
    margin-bottom: 80px;
  }
}

.stats-blocks {
  flex-basis: 320px;

  @include respond-to('mobile') {
    margin-top: 202px;
  }
}
.stats-block {
  color: $c-white;
  text-align: center;
  margin-bottom: 24px;
}

.stats-image {
  background-image: url('../../assets/stat.svg');
  background-repeat: no-repeat;
  width: 136px;
  height: 136px;
  background-size: contain;
  margin: 0 auto;
  margin-bottom: 8px;
}

.stats-description {
  margin-top: 8px;
}

.partners-title {
  @include col(12);
  text-align: center;
}
.partners-title {
  margin-top: 120px;

  @include respond-to('mobile') {
    margin-top: 86px;
  }
}

.partner {
  @include col(4);
  min-height: 185px;
  border-radius: 16px;
  display: flex;
  justify-content: center;

  @include respond-to('mobile') {
    margin-bottom: 32px;
    margin-left: 20px;
    margin-right: 20px;
  }

  &:nth-child(1) {
    grid-column: 1 / 5;
  }
  &:nth-child(2) {
    grid-column: 5 /9;
  }
  &:nth-child(3) {
    grid-column: 9 / -1;
  }

  img {
    max-height: 100px;
  }
}
.google,
.stripe {
  margin-top: 120px;
}
.form-wrapper {
  @include col(12);
  padding-left: 176px;
  padding-top: 64px;
  padding-right: 176px;
  padding-bottom: 80px;
  background: linear-gradient(90deg, #7621d9 0%, #8c21d9 100%);
  border-radius: 40px;
  margin: 120px 0;

  @include respond-to('mobile') {
    padding: 24px;
    margin: 80px 20px;
  }
}
.form-block {
  color: $c-white;
}

.form {
  display: flex;
  flex-direction: row;

  @include respond-to('mobile') {
    display: block;
    text-align: center;
  }
}

.form-input {
  flex-grow: 2;
  border: 1px solid #f3ebff;
  border-radius: 8px 0px 0px 8px;
  padding: 22px 24px;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: darken(#c7c2cc, 10);

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: darken(#c7c2cc, 10);
  }

  @include respond-to('mobile') {
    box-shadow: 0px 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
    border-radius: 12px;
    width: 100%;
    margin-bottom: 16px;
  }
}

.form-button {
  color: white;
  min-height: 60px;
  width: 220px;
  background: $c-brand;
  border-radius: 0px 8px 8px 0px;
  border: none;
  cursor: pointer;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;

  @include respond-to('mobile') {
    border-radius: 12px;
    width: 100%;
  }
}

.form-description {
  margin: 32px 0;
}

.what-inside {
  width: 1024px;
  max-width: calc(100% - 40px);
  margin: 120px auto;
  box-sizing: border-box;
  padding: 40px 40px 40px 64px;
  background-color: #fff;
  border-radius: 32px;

  @include respond-to('mobile') {
    padding: 40px;
  }

  &__title {
    text-align: center;
  }

  &__description {
    text-align: center;
    max-width: 692px;
    margin: 24px auto 32px;
  }
}

.database {
  margin-top: 80px;

  &__title {
    text-align: center;
    margin-bottom: 40px;
  }

  &__image {
    margin: 0 auto;
    display: block;
    width: 1024px;
    height: 370px;
    background-image: url('../../assets/database.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__description {
    width: 673px;
    max-width: calc(100% - 40px);
    text-align: center;
    margin: 0 auto;
  }
}

.try {
  max-width: 1024px;
  width: calc(100% - 40px);
  margin: 0 auto;
  background: #fff url('../../assets/try.svg') no-repeat top right;
  box-shadow: 0 16px 60px rgba(42, 11, 77, 0.25);
  border-radius: 16px;
  padding: 40px 56px 10px;

  @include respond-to('mobile') {
    margin-top: 230px;
    transform: none;
    background-position-y: 355px;
    background-position-x: center;
  }

  &__container {
    max-width: 420px;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__button {
    background: linear-gradient(258.57deg, #a04dff 41.48%, #871eff 100%);
    box-shadow: 0 12px 40px rgba(160, 77, 255, 0.5);
    border-radius: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(calc(50% + 10px));
    cursor: pointer;
    padding: 28px 0;
    text-decoration: none;
    transition: box-shadow 140ms ease-out;

    svg {
      margin-left: 8px;
    }

    &:hover {
      box-shadow: 0 6px 20px rgba(160, 77, 255, 0.5);
    }

    @include respond-to('mobile') {
      margin-top: 310px;
    }
  }
}
.product-hunt {
  width: 250px;
  height: 54px;
  background-image: url('../../assets/product-hunt.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 auto;
}
.soab {
  background-image: url('../../assets/background-peoples.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 1200px;
  height: 696px;
  margin: 0 auto;
}
.soab-title {
  font-style: normal;
  font-weight: 800;
  font-size: 96px;
  line-height: 72px;
  /* identical to box height, or 75% */

  text-align: center;
  letter-spacing: -3.5px;

  /* Black */

  color: #000000;
  margin-bottom: 7px;
  padding-top: 73px;
}
.soab-animation {
  background-image: url('../../assets/everything.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 652px;
  height: 215px;
  margin: 0 auto;
}
.soab-subtitle {
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -3.5px;
  color: #000000;
  width: 563px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.soab-button {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-basis: 15%;
  -webkit-box-align: center;
  align-items: center;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  /* identical to box height, or 111% */
  padding: 30px 60px;
  letter-spacing: -1.75px;

  color: #ffffff;
  background: linear-gradient(258.57deg, #a04dff 41.48%, #871eff 100%);
  box-shadow: 0px 12px 40px rgba(160, 77, 255, 0.5), 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 458px;
  height: 104px;
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: 80px;
}
.soab-button-title {
}
.banner-front {
  background-image: url('../../assets/banner-front.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 1024px;
  height: 364.52px;
  display: grid;
  margin: 0 auto;
  margin-bottom: 8px;
}
.local-experts-title {
  display: grid;
  margin: 0 auto;
  width: 1024px;
  font-style: normal;
  font-weight: 800;
  font-size: 54px;
  line-height: 56px;
  /* or 104% */

  text-align: center;
  letter-spacing: -2.9px;

  /* Black */

  color: #000000;
  padding: 0px 170px;
  margin-bottom: 40px;
}
.local-experts {
  display: grid;
  width: 1024px;
  margin: 0 auto;
  grid-template-columns: repeat(12, 1fr);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */

  /* Black */

  color: #000000;
  margin-bottom: 40px;
}
.local-experts-text {
  grid-column: span 6/9;
  display: block;
  margin-left: -30px;
}
.local-experts-text-1 {
  margin-bottom: 16px;
}
.local-experts-image {
  grid-column: span 3/12;
  background-image: url('../../assets/psina.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 350px;
  height: 270px;
  margin-top: -15px;
  cursor: pointer;
}
.soab-button-icon {
  background-image: url('../../assets/soab-button-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 56px;
  height: 56px;
}
.black-guy {
  background-image: url('../../assets/black-guy.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 194.07px;
  height: 177px;
}
.white-man {
  background-image: url('../../assets/white-man.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 144.43px;
  height: 158.87px;
}
.asian-girl {
  background-image: url('../../assets/asian-girl.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 150.67px;
  height: 172.06px;
}
.black-dude {
  background-image: url('../../assets/black-dude.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 262.21px;
  height: 246.13px;
}
.black-girl {
  background-image: url('../../assets/black-girl.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 246px;
  height: 278.81px;
}
.white-women {
  background-image: url('../../assets/white-women.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 220px;
  height: 231.86px;
}
