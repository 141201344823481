@import 'src/styles/variables';
@import 'src/styles/mixins';

.footer {
  grid-column: span 12;
  min-height: 360px;
  padding-bottom: 24px;

  &__corner {
    width: 100%;
  }
}

.layout-center-wrapper {
  @include wrapper;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @include respond-to('mobile') {
    display: block;
    padding: 24px;
    padding-top: 80px;
    padding-bottom: 24px;
  }
}

.links {
  @include col(3);
  padding-top: 80px;
  padding-bottom: 56px;

  @include respond-to('mobile') {
    padding: 0;
  }
}

.link-header {
  color: #A04DFF !important;
}

.link {
  color: #000000;
  cursor: pointer;
  // margin-bottom: 8px;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.01em;
  transition: color 140ms ease-out;

  &:hover {
    color: $c-violet-secondary;
  }

  &._caption {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    color: #9A989C;
    &:hover {
      color: $c-violet-secondary;
    }
  }

  &._logo {
    width: 139px;
    height: 25px;
    background-image: url('../../../auth/assets/auth_logo.svg');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: left;
    margin-top: -60px;
  }

  span {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.12em;
    margin-bottom: 8px;
    display: inline-block;
  }
}
.links-wrapper {
  display: flex;
  gap: 72px;
  justify-content: space-around;

  @include respond-to('mobile') {
    display: block;
  }
}

.copyright {
  @include col(12);
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #b1adb8;

  @include respond-to('mobile') {
    justify-content: flex-start;
    margin-left: 20px;
  }
}
