.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.fullscreen {
  background-color: #fff;
  min-height: 100vh;
  min-width: 100vw;
}

.single {
  background-color: #fff;
  height:100%;
  width:100%;
}
.fullbody {
  background-color: #fff;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
