@import 'src/styles/mixins';
@import 'src/styles/variables';

.clients {
  @include col(7);
}

.notifications {
  @include col(5);
  margin-bottom: 200px;
}

.country {
  background: #ebe8ed;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 18px;
}

.clients-title {
  margin-top: 40px;
  margin-bottom: 14px;
}

.company-block {
  background: #ffffff;
  /* Input Shadow */

  box-shadow: 0px 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
  border-radius: 16px;
  padding: 24px;
  margin-top: 16px;
  display: flex;
  gap: 16px;
  cursor: pointer;
}

.company-name {
  color: #b4b3b5;
  margin: 4px 0px;
}
.company-country {
  margin: 4px 0px;
}

.flag {
  margin-right: 8px;
}

.notification-list {
  padding: 24px;
  background: #ebe8ed;
  border-radius: 16px;
}

.notification-more {
  color: #a04dff;
  text-align: center;
  margin-top: 24px;
  cursor: pointer;
}
