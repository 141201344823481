@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.0312em;
  color: $c-black;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
 

  &:checked ~ .checkmark {
    background-color: $c-brand;
    border: none;
  }
  &:checked ~ .checkmark:after {
    display: block;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: $c-white;
  border: 1px solid #918a99;

  border-radius: 4px;

  clear: both;
  transform-origin: left bottom;
  transition-property: transform,opacity,height,padding,margin;
  transition-duration: .14s;
  transition-timing-function: ease-in;
  transition: all .2s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: $c-black;
}
