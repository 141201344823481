@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  padding: 0 16px;
}

.avatar {
  object-fit: cover;
  margin: 0 auto;
  display: block;
}

.username {
  text-align: center;
  margin: 16px 0;
}

.info__link {
  color: $c-brand;
  cursor: pointer;
}

.info {
  border-top: 1px solid #c7c2cc;
  padding-top: 16px;
  padding-bottom: 16px;

  &._languages {
    display: flex;
    gap: 8px;
  }
}

.info__email {
  margin-bottom: 8px;
}

.language {
  cursor: pointer;

  &._active {
    color: $c-brand;
  }
}
