@import 'src/styles/mixins';
@import 'src/styles/variables';
.button {
  border: none;
  width: 100%;
  color: $c-white;
  padding: 16px 0;
  cursor: pointer;
  background: $c-brand;
  border-radius: 12px;
  font-weight: 800;
  font-size: 16.5px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #ffffff;

  &:hover {
    background: darken($c-brand, 5%);
  }

  &:disabled {
    opacity: 0.1;
  }
}
