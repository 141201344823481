@import 'src/styles/mixins';
@import 'src/styles/variables';

.partnership {
  background: linear-gradient(90deg, #f8f2ff 0%, #f2f9ff 100%);
  @include respond-to('mobile') {
    margin: 0 20px;
  }
}

.wrapper {
  @include wrapper;
}

.main {
  @include col(12);

  width: 100%;
  max-width: 672px;

  &__description-title {
    margin-bottom: 40px;
  }

  &__description-sub-title {
    margin-bottom: 24px;
  }
}

.how {
  @include col(12);

  margin-top: 40px;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 32px 40px 40px;

  &__examples {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 33px;
    margin-top: 16px;

    @include respond-to('mobile') {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      row-gap: 33px;
    }
  }

  &__example {
    width: 320px;
  }

  &__example-image {
    background: linear-gradient(90deg, #FCFAFF 0%, #FAFBFF 100%);
    border-radius: 16px;
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 30px;
    box-sizing: border-box;
    margin-bottom: 6px;
  }

  &__example-image img {
    max-height: 100%;
    max-width: 100%;
  }
}


.what, .how-much {
  @include col(12);

  margin-top: 124px;


  &__description {
    width: 100%;
    max-width: 672px;
    margin-top: 24px;
  }
}

.how-much {
  @include col(12);
  padding-bottom: 54px;
  background: url('../../assets/how-much.png') no-repeat bottom right;
  background-size: 300px auto;
  margin-bottom: 82px;

  @include respond-to('mobile') {
    background: transparent;
  }

  :last-child {
    margin-top: 24px;
  }
}

.how-to {
  @include col(12);

  width: 100%;
  max-width: 672px;
  margin: 0 auto 120px;

  &__list {
    font-size: 20px;
    padding: 0;
    margin: 24px 0 46px 32px;
  }
}

.form {
  width: 100%;
  max-width: 496px;
  margin: 80px auto 0;

  &_submitted {
    background-color: #fff;
    border-radius: 16px;
    height: 200px;
    text-align: center;
    padding: 40px;
    box-sizing: border-box;

    & :last-child {
      margin-top: 16px;
    }
  }

  &__label {
    margin-top: 24px;
    margin-bottom: 8px;

    sup {
      color: #A873E5;
    }
  }

  &__submit {
    width: 100%;
    margin-top: 45px;
    border: none;
    background-color: #A04DFF;
    color: #fff;
    cursor: pointer;
    border-radius: 12px;
    padding: 16px 0;
  }
}
