@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: initial;
}
.country-facts {
  grid-column: span 12;
  border-radius: 32px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 48px 62px;
  white-space: nowrap;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.country-facts-header {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 28px;
}
.country-facts-body {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px 40px;
  grid-auto-rows: 80px;
}
.country-facts-flag-cover {
  min-width: 64px;
  min-height: 64px;
  max-width: 64px;
  max-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.country-facts-flag {
  width: 100%;
  height: auto;
  object-fit: cover;
  opacity: 0.87;
  box-shadow: 0px 2.66667px 10.6667px rgba(94, 64, 128, 0.25);
  border-radius: 5.33333px;
}
.country-facts-name {
  grid-column: span 2;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  /* identical to box height, or 111% */

  letter-spacing: -1.75px;

  color: #000000;
}
.country-facts-president-values,
.country-facts-header {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  grid-column: span 1;
  margin-top: 16px;
}
.country-facts-president {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
  white-space: normal;
  color: #000000;
}
.country-facts-president-avatar-cover {
  border: 2px solid #ffffff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}
.country-facts-president-avatar {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.label-facts {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: -0.5px;

  color: #000000;
}
.label-facts-footer {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: -0.5px;

  color: #000000;
  margin-top: 16px;
}
.label-language {
  grid-column: 3;
  margin-right: 100px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: -0.5px;

  color: #000000;
}

.label-name {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: -0.5px;

  color: #000000;
}

.country-facts-gdp-values {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  grid-column: span 1;
  margin-top: 16px;
}
.country-facts-gdp-per-cap {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  grid-column: span 1;
}
.country-facts-gdp {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  color: #000000;
}
.country-facts-gdp-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  height: 8px;
  /* identical to box height, or 133% */

  color: #000000;
}

.country-facts-language {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  color: #000000;
  &:first-of-type {
    margin-top: 16px;
  }
}
.country-facts-population {
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  grid-column: span 1;
}
.country-facts-population-value {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  color: #000000;
  margin-top: 16px;
}
.country-facts-gdp-estimate {
  margin-left: 15px;
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  height: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  letter-spacing: 0.01em;

  color: #000000;
  /* identical to box height, or 133% */

  color: #000000;
}
.country-facts-population-estimate {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  height: 8px;
  /* identical to box height, or 133% */

  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  letter-spacing: 0.01em;

  color: #000000;
}
.label-facts-currency {
  grid-column: span 2;
  margin-left: 100px;
  margin-right: 100px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: -0.5px;

  color: #000000;
  margin-top: 48px;
}
.label-facts-time-zone {
  margin-right: 100px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: -0.5px;

  color: #000000;
  margin-top: 48px;
}
.country-facts-currency {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
  margin-top: 8px;
  color: #000000;
}
.country-facts-time-zone {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  color: #000000;
  margin-top: 8px;
}
.country-facts-currency-symbol {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  margin-left: 8px;
  margin-top: auto;
}
.country-facts-time-zone {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  color: #000000;
  margin-top: 8px;
}
.country-facts-currency-block {
  display: flex;
}
.header-map {
  margin-left: 425px;

  path {
    fill: #f1e6ff;
  }
}
.country-facts-left {
  grid-column: span 9;
}
.country-facts-right {
  grid-column: span 3;
}
