@import 'src/styles/variables';
@import 'src/styles/mixins';

.add-country {
  background: #f1e6ff;
  box-shadow: 0px 8px 24px rgba(160, 77, 255, 0.12);
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-right: 15px;
  text-decoration: none;
  transition: box-shadow 140ms ease-out;
  margin-right: auto;
}
.plus-icon {
  width: 64px;
  height: 48px;
  background-image: url('./assets/plus_icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16.5px;
  line-height: 24px;
  /* identical to box height, or 145% */

  letter-spacing: 0.01em;

  /* Violet Primary */

  color: #a04dff;
  margin-left: -10px;
  margin-right: 10px;
}
.price {
  /* Text Large */

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16.5px;
  line-height: 24px;
  /* identical to box height, or 145% */

  letter-spacing: 0.01em;

  /* Black */

  color: #000000;
}
