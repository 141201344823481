@import 'src/styles/mixins';
@import 'src/styles/variables';

.content-title {
  margin-top: 8px;
}

.tasks {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  box-sizing: border-box;
}

.task_count {
  color: $c-brand;
}

.task {
  flex-basis: 50%;
  height: 100%;
  padding: 16px;
  background: #eee9f5;
  border-radius: 16px;
}

.task-name {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0375em;
  color: $c-black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.task-block {
  background: $c-white;
  padding: 16px;
  margin-top: 16px;
  box-shadow: 0px 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
  border-radius: 8px;
  cursor: pointer;
}

.task-avatar {
  flex-shrink: 0;
  margin-right: 8px;
  height: 24px;
}

.task-status {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #2e2933;
  padding: 6px;
  background: #f7f5fa;
  border-radius: 4px;
}

.task-title {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 8px;
  margin-bottom: 4px;
}

.task-description {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0375em;
  color: #918a99;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clock-content {
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
  border-radius: 16px;
  padding: 24px;
  margin: 40px 0;
}

.clock-blocks {
  display: flex;
  gap: 16px;
}

.clock-block {
  flex-basis: 50%;
  background: #faf5ff;
  border-radius: 16px;
  min-height: 146px;
  padding: 16px;
}

.clocks {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.clock {
  &._analog {
    [class$='react-clock'] {
      width: 75px !important;
      height: 75px !important;
    }
  }
}

.clock-title {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
}

.holiday-block {
  background: #f1e6ff;
  border-radius: 12px;
  padding: 16px;
  min-height: 88px;
  margin-top: 8px;
}
.holiday-name {
  margin-top: 8px;
}

.holiday-week {
  color: #c999ff;
}

.empty {
  margin-top: 16px;
  background-color: #F3EDFA;
  border-radius: 8px;
  padding: 16px;
  color: #C7C2CC;
}
