@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: initial;
}

.experts-blocks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px 32px;
}
.experts-block {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 8px 8px;
  background: #ffffff;
  /* Input Shadow */

  box-shadow: 0px 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
  border-radius: 16px;
  width: 320px;
  height: 126px;
  padding: 16px;
}
.experts-block-info {
  grid-column: span 8;
}
.experts-block-avatar-cover {
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.experts-block-avatar {
  width: 100%;
  height: auto;
  object-fit: cover;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.experts-block-name {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: -0.5px;

  color: #000000;
}
.experts-block-social-network {
  grid-column: span 3;
  justify-self: right;
}

.experts-block-occupation,
.experts-block-company {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Gray 5 */

  color: #9a989c;
  margin-top: 5px;
}
.occupation-icon {
  background-image: url('../../assets/occupation.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 16px;
  width: 16px;
  margin-right: 5px;
}
.company-icon {
  background-image: url('../../assets/company.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 16px;
  width: 16px;
  margin-right: 5px;
}
.contacts {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  letter-spacing: 0.01em;

  color: #000000;
  /* Violet Secondary 1 */

  background: #faf5ff;
  border-radius: 16px;
  padding: 8px;
  margin-top: 7px;
  margin-left: -8px;
}

.email-icon {
  background-image: url('../../assets/email.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.phone-icon {
  background-image: url('../../assets/phone.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 16px;
  width: 16px;
  margin-right: 5px;
}
