.select {
  [class*='-control'] {
    box-shadow: 0px 1px 8px rgba(60, 0, 128, 0.05), 0px 2px 16px rgba(60, 0, 128, 0.05);
    border-radius: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    border: none;
    padding: 8px;
  }

  :global .project-select__menu-list {
    font-size: 16px;
  }
}
